<template>
  <div class="activities">
    <page-header :title="youthHomeLabel" :icon="youthHomeIcon"></page-header>
    <div class="youth-home-days">
      <b-row class="date-header">
        <b-col>
          <b>{{ seanceDate|dateToString('dddd LL') }}</b>
        </b-col>
        <b-col cols="3" class="text-right nav-date">
          <a class="btn btn-sm btn-secondary" href @click.prevent="setLastWeek()" title="Semaine précédente">
            <i class="fa fa-angle-double-left"></i>
          </a>
          <a class="btn btn-sm btn-secondary" href @click.prevent="setYesterday()" title="Hier">
            <i class="fa fa-chevron-left"></i>
          </a>
          <a class="btn btn-sm btn-secondary" href @click.prevent="setToday()" title="Aujourd'hui">
            <i class="fa fa-calendar-o"></i>
          </a>
          <a class="btn btn-sm btn-secondary" href @click.prevent="setTomorrow()" title="Demain">
            <i class="fa fa-chevron-right"></i>
          </a>
          <a class="btn btn-sm btn-secondary" href @click.prevent="setNewtWeek()" title="Semaine suivante">
            <i class="fa fa-angle-double-right"></i>
          </a>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-input type="date" v-model="rawDateAsString" id="seanceDay"></b-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <loading-gif :loading-name="youthHomesLoading"></loading-gif>
          <b-row v-if="youthHomes.length === 0" class="ut-no-seance-type">
            <b-col><h3><i class="empty-text">Aucune séance</i></h3></b-col>
          </b-row>
          <b-row
            v-for="elt in youthHomes"
            :key="elt.youthHome.id"
            class="row-line"
            :style="getHeaderStyle(elt)"
          >
            <b-col cols="4">
              <b>{{ elt.youthHome.name }}</b>
            </b-col>
            <b-col cols="3">
              <router-link
                :style="getHeaderStyle(elt)"
                :to="getLinkToActivity(elt.youthHome.activity)">Liste des adhérents
              </router-link>
            </b-col>
            <b-col cols="5">
              <b-row v-if="elt.seanceElements.length === 0" class="ut-no-seance-type">
                <b-col>
                  <i class="empty-text" :style="getHeaderStyle(elt)">
                    Aucune séance le {{ seanceDateAsString | dateToString('dddd LL') }}
                  </i>
                </b-col>
              </b-row>
              <b-row
                v-for="elt2 in elt.seanceElements"
                 :key="elt2.seanceType.id"
                 class="ut-seance-type"
              >
                <b-col cols="7">
                  <router-link
                    :style="getHeaderStyle(elt)"
                    :to="getLinkToInscriptions(elt.youthHome, elt2.seanceType, elt2.seancePeriod)"
                  >
                    {{ getEltName(elt2) }}
                  </router-link>
                  <router-link
                    v-if="elt2.seanceType.isWeeklyListVisible()"
                    :style="getHeaderStyle(elt)"
                    :to="getLinkToWeekInscriptions(elt.youthHome, elt2.seanceType, elt2.seancePeriod)"
                    v-b-tooltip="'Liste hebdomadaire'"
                  >
                    <i class="fa fa-calendar"></i>
                  </router-link>
                  <router-link
                    v-if="elt2.seanceType.isSeveralWeeksVisible()"
                    :style="getHeaderStyle(elt)"
                    :to="getLinkToSeveralWeeksInscriptions(elt.youthHome, elt2.seanceType, elt2.seancePeriod)"
                    v-b-tooltip="'' + elt2.seanceType.weeksNumber + ' semaines'"
                  >
                    <i class="fa fa-calendar"></i>
                  </router-link>
                </b-col>
                <b-col cols="5" class="text-right">
                  <counter-label :counter="elt2.individualsCount" label="inscrit"></counter-label>
                  <div class="small-text" v-if="elt2.absenceIndividualsCount">
                    <counter-label :counter="elt2.absenceIndividualsCount" label="absent"></counter-label>
                  </div>
                  <div class="small-text" v-if="elt2.todoIndividualsCount">
                    <router-link
                      :style="getHeaderStyle(elt)"
                      :to="confirmRoute(elt, elt2)"
                    >
                      {{ elt2.todoIndividualsCount }} à traiter
                    </router-link>
                  </div>
                  <div class="small-text" v-if="elt2.waitingIndividualsCount">
                    <router-link
                      :style="getHeaderStyle(elt)"
                      :to="waitingRoute(elt, elt2)"
                    >
                      {{ elt2.waitingIndividualsCount }} en attente
                    </router-link>
                  </div>
                  <div class="small-text" v-if="elt2.refusedIndividualsCount">
                    <router-link
                      :style="getHeaderStyle(elt)"
                      :to="refusedRoute(elt, elt2)"
                    >
                      <counter-label label="refusé" :counter="elt2.refusedIndividualsCount">
                      </counter-label>
                    </router-link>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <daily-workshops :day="seanceDateAsString"></daily-workshops>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <div>
          <ul class="app-menu">
            <li v-if="hasPerm('youth.view_seanceinscription')">
              <router-link :to="{ name: 'youth-inscriptions-summary', }">
                <i class="fas fa-person-circle-question"></i> Fréquentations {{ youthHomeLabel }}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_youthhomeactivityinscription')">
              <router-link :to="{ name: 'contact-members', }">
                <i class="fa fa-envelope"></i> Contacter les membres
              </router-link>
            </li>
            <li v-if="hasPerm('youth.change_seance')">
              <router-link :to="{ name: 'seances-config', }">
                <i class="fa fa-cogs"></i> Configuration des séances
              </router-link>
            </li>
            <li v-if="hasAllPerms(['payments.add_invoice', 'youth.view_youthhomeactivity'])">
              <router-link :to="{ name: 'youth-invoicing', }">
                <i class="fa fa-receipt"></i> Facturation {{youthHomeLabel}}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_seanceinscription')">
              <router-link :to="{ name: 'clocking-checking', }">
                <i class="fa fa-clock"></i> Vérification du pointage
              </router-link>
            </li>
            <li v-if="hasAllPerms(['portal.view_', 'youth.view_seanceinscription'])">
              <router-link :to="{ name: 'confirm-inscriptions', }">
                <i class="fa fa-check"></i> Inscriptions à confirmer
              </router-link>
            </li>
            <li v-if="hasAllPerms(['portal.view_', 'youth.view_seanceinscription'])">
              <router-link :to="{ name: 'inscription-confirmations-list', }">
                <i class="fa fa-history"></i> Liste des inscriptions acceptées ou refusées
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import DailyWorkshops from '@/components/Youth/DailyWorkshops'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeSeancePeriod, makeSeanceType, makeYouthHome, StatusFilter } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import CounterLabel from '@/components/Controls/CounterLabel'
import { daysAgo } from '@/utils/dates'
import store from '@/store'

export default {
  name: 'youth-homes',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
    DailyWorkshops,
  },
  props: {
    day: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      youthHomesLoading: 'youth-homes',
      youthHomes: [],
      rawDateAsString: '',
      seanceDateAsString: '',
      originalDate: '',
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    rawDateAsString: function() {
      if (this.rawDateAsString) {
        this.seanceDateAsString = this.rawDateAsString
      }
    },
    seanceDate: function(newValue) {
      let isValid = false
      if (newValue) {
        isValid = moment(newValue, 'YYYY-MM-DD').isValid()
      }
      if (isValid) {
        let newDate = dateToString(moment(newValue, 'YYYY-MM-DD').toDate(), 'YYYY-MM-DD')
        this.patchUrl(newDate)
        this.loadActivities(newValue)
      } else {
        this.rawDateAsString = dateToString(moment().toDate(), 'YYYY-MM-DD')
      }
    },
  },
  computed: {
    seanceDate() {
      return moment(this.seanceDateAsString, 'YYYY-MM-DD').toDate()
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
  },
  mounted() {
    this.rawDateAsString = this.day || dateToString(moment().toDate(), 'YYYY-MM-DD')
    this.originalDate = this.day
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getEltName(elt) {
      return [elt.seanceType.name, elt.seancePeriod.name].map(
        itm => itm.trim()
      ).filter(
        itm => (itm && itm !== '-')
      ).join(' - ')
    },
    setToday() {
      this.rawDateAsString = dateToString(moment().toDate(), 'YYYY-MM-DD')
    },
    setYesterday() {
      const date = moment(this.rawDateAsString).add(-1, 'days')
      this.rawDateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setTomorrow() {
      const date = moment(this.rawDateAsString).add(1, 'days')
      this.rawDateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setLastWeek() {
      const date = moment(this.rawDateAsString).add(-7, 'days')
      this.rawDateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    setNewtWeek() {
      const date = moment(this.rawDateAsString).add(7, 'days')
      this.rawDateAsString = dateToString(date.toDate(), 'YYYY-MM-DD')
    },
    getHeaderStyle(elt) {
      return elt.youthHome.getHeaderStyle()
    },
    getLinkToActivity(activityId) {
      return { name: 'youth-homes-detail', params: { activityId: '' + activityId, }, }
    },
    getLinkToInscriptions(youthHome, seanceType, seancePeriod) {
      let data = {
        name: 'youth-homes-day',
        params: {
          day: this.seanceDateAsString,
          youthHome: '' + youthHome.id,
          seanceType: '' + seanceType.id,
          seancePeriod: '' + seancePeriod.id,
        },
      }
      return data
    },
    getLinkToWeekInscriptions(youthHome, seanceType, seancePeriod) {
      let data = {
        name: 'youth-homes-week',
        params: {
          day: this.seanceDateAsString,
          youthHome: '' + youthHome.id,
          seanceType: '' + seanceType.id,
          seancePeriod: '' + seancePeriod.id,
        },
      }
      return data
    },
    getLinkToSeveralWeeksInscriptions(youthHome, seanceType, seancePeriod) {
      let data = {
        name: 'youth-homes-week',
        params: {
          day: this.seanceDateAsString,
          youthHome: '' + youthHome.id,
          seanceType: '' + seanceType.id,
          seancePeriod: '' + seancePeriod.id,
        },
      }
      return data
    },
    async loadActivities(seanceDate) {
      this.startLoading(this.youthHomesLoading)
      let dateAsTr = dateToString(seanceDate, 'YYYY-MM-DD')
      let url = '/api/youth/youth-homes-day/' + dateAsTr + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.youthHomes = resp.data.map(
          elt => {
            let youthHome = makeYouthHome(elt)
            let seanceElements = elt['seance_elements'].map(
              elt2 => {
                return {
                  id: elt2['seance_type']['id'] * 10000 + elt2['seance_period']['id'],
                  seanceType: makeSeanceType(elt2['seance_type']),
                  seancePeriod: makeSeancePeriod(elt2['seance_period']),
                  individualsCount: elt2['individuals_count'],
                  todoIndividualsCount: elt2['todo_individuals_count'],
                  waitingIndividualsCount: elt2['waiting_individuals_count'],
                  refusedIndividualsCount: elt2['refused_individuals_count'],
                  absenceIndividualsCount: elt2['absence_individuals_count'],
                }
              }
            )
            return {
              youthHome: youthHome,
              seanceElements: seanceElements,
            }
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.youthHomesLoading)
    },
    confirmRoute(elt, elt2) {
      return {
        name: 'confirm-inscriptions',
        query: {
          period: elt2.seancePeriod.id,
          'youth_homes': elt.youthHome.id,
          'seance_date': moment(this.seanceDate).format('YYYY-MM-DD'),
          paid: '0',
        },
      }
    },
    refusedRoute(elt, elt2) {
      return {
        name: 'confirm-inscriptions',
        query: {
          period: elt2.seancePeriod.id,
          'youth_homes': elt.youthHome.id,
          'seance_date': moment(this.seanceDate).format('YYYY-MM-DD'),
          paid: '0',
          status: '' + StatusFilter.Refused,
        },
      }
    },
    waitingRoute(elt, elt2) {
      const isDatePassed = daysAgo(this.seanceDate) > 0
      return {
        name: 'confirm-inscriptions',
        query: {
          period: elt2.seancePeriod.id,
          'youth_homes': elt.youthHome.id,
          'seance_date': moment(this.seanceDate).format('YYYY-MM-DD'),
          paid: '0',
          status: '' + (isDatePassed ? StatusFilter.Refund : StatusFilter.Waiting),
        },
      }
    },
    patchUrl(newValue) {
      // met à jour l'url (sans rechargement) avec la date sélectionné si
      // différente de la date du jour
      let initialPath = this.$route.path
      let today = dateToString(moment().toDate(), 'YYYY-MM-DD')
      let updatedPath = ''
      if (this.originalDate) {
        updatedPath = initialPath.replace(this.originalDate, newValue)
      } else {
        if (newValue === today) {
          updatedPath = initialPath
        } else {
          updatedPath = initialPath + '/' + newValue
        }
      }
      history.pushState(
        {},
        null,
        updatedPath
      )
    },
  },
}
</script>

<style lang="less">
  .youth-home-days {
    margin-top: 20px;
    padding: 10px;
  }
  .date-header {
    margin-bottom: 5px;
  }
  .daily-workshops {
    margin-top: 30px;
  }
  .nav-date a {
    margin: 5px 2px;
  }
</style>
