<template>
  <div class="daily-workshops" v-if="day">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName) && workshops.length">
      <h3>Ateliers du {{ day | dateToString('dddd LL') }}</h3>
      <b-row class="row-line" v-for="workshop of workshops" :key="workshop.id">
        <b-col><b>{{ workshop.name }}</b></b-col>
        <b-col>{{ workshop.getMomentName() }}</b-col>
        <b-col class="text-right">
          <router-link :to="getWorkshopLink(workshop)">
            <counter-label :counter="getInscriptionsCount(workshop, 0)" label="inscrit"></counter-label>
          </router-link>
          <div class="small-text" v-if="getInscriptionsCount(workshop, 1)">
            {{ getInscriptionsCount(workshop, 1) }} en attente
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="separator" v-if="excursions.length && workshops.length"></div>
    <div v-if="!isLoading(loadingName) && excursions.length">
      <h3>Sorties du {{ day | dateToString('dddd LL') }}</h3>
      <b-row class="row-line" v-for="excursion of excursions" :key="excursion.id">
        <b-col><b>{{ excursion.name }}</b></b-col>
        <b-col></b-col>
        <b-col class="text-right">
          <router-link :to="getExcursionLink(excursion)">
            <counter-label :counter="getExcursionsCount(excursion, 0)" label="inscrit"></counter-label>
          </router-link>
          <div class="small-text" v-if="getExcursionsCount(excursion, 1)">
            {{ getExcursionsCount(excursion, 1) }} en attente
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'
import { makeWorkshop } from '@/types/youth'
import { BackendApi } from '@/utils/http'

export default {
  name: 'DailyWorkshops',
  components: { LoadingGif, CounterLabel, },
  mixins: [BackendMixin],
  props: {
    day: String,
  },
  data() {
    return {
      workshops: [],
      excursions: [],
      inscriptionsMap: {},
      excursionsMap: {},
      loadingName: 'daily-workshops',
    }
  },
  watch: {
    day: function(value) {
      this.loadWorkshops(value)
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadWorkshops(day) {
      this.workshops = []
      this.inscriptionsMap = {}
      this.excursions = []
      this.excursionsMap = {}
      if (day) {
        this.startLoading(this.loadingName)
        let url = '/api/youth/daily-workshops/' + day + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          for (const elt of resp.data.workshops) {
            const workshop = makeWorkshop(elt)
            this.workshops.push(workshop)
            this.inscriptionsMap['' + workshop.id] = [
              elt['inscriptions_count'], elt['waiting_inscriptions_count']
            ]
          }
          for (const elt of resp.data.excursions) {
            const workshop = makeWorkshop(elt) // une sortie qui se fait passer pour un atelier ;)
            this.excursions.push(workshop)
            this.excursionsMap['' + workshop.id] = [
              elt['inscriptions_count'], elt['waiting_inscriptions_count']
            ]
          }
          this.inscriptionsMap = { ...this.inscriptionsMap, }
          this.excursionsMap = { ...this.excursionsMap, }
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    getInscriptionsCount(workshop, index) {
      const elt = this.inscriptionsMap['' + workshop.id]
      if (elt) {
        return elt[index]
      }
      return 0
    },
    getExcursionsCount(excursion, index) {
      const elt = this.excursionsMap['' + excursion.id]
      if (elt) {
        return elt[index]
      }
      return 0
    },
    getWorkshopLink(workshop) {
      return {
        name: 'workshop-daily-inscriptions', params: { day: this.day, id: workshop.id, listType: 'W', },
      }
    },
    getExcursionLink(excursion) {
      return {
        name: 'workshop-daily-inscriptions', params: { day: this.day, id: excursion.id, listType: 'E', },
      }
    },
  },
  mounted() {
    this.loadWorkshops(this.day)
  },
}
</script>
<style lang="less">
.daily-workshops .separator {
  margin: 20px 0;
}
</style>
